import Grid from "@mui/material/Grid";
import {Typography} from "@mui/material";
import {GridRow} from "./GridRow";

import * as React from "react";
import {useContext} from "react";
import {AppContext} from "../../AppContext";
import {TextBox} from "./TextBox";
import {CustomLineChart} from "../Charts/CustomLineChart";
import {CustomGoogleLineChart} from "../Charts/CustomGoogleLineChart";
import Paper from "@mui/material/Paper";

import {extractBrandSales} from "./extractBrandSales";
import {IBrandSalesShare} from "../../types.ts";
import Box from "@mui/material/Box";

function formatCurrency(value: number, currency = 'SEK', locale = 'sv-SE') {
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        maximumFractionDigits: 0
    }).format(value);
}

function formatNumberSwedishStyle(value: number) {
    return new Intl.NumberFormat('sv-SE', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }).format(value);
}
export const LagKPI = () => {
    const {asacolSales, dificlirSales, asacolOralSales, country, lastUpdated, maxPeriod, isDesktop, ppID} = useContext(AppContext);
    const getLastUpdated = (type: string) => {
        const tmpLastUpdated = lastUpdated[country][type];
        if (tmpLastUpdated) return new Date(tmpLastUpdated);
        return new Date();
    }
    const asacol1600 = extractBrandSales({sales: asacolSales, maxPeriod});
    const asacolOral = extractBrandSales({sales: asacolOralSales, maxPeriod, units: false});
    const dificlir = extractBrandSales({sales: dificlirSales, maxPeriod});
    const LagKPIInner = () => {
        return (
            <React.Fragment>
                <Grid item xs={12} marginBottom={3} hidden={!!ppID} >
                    <Typography paddingBottom={0} paddingTop={1} paddingLeft={2} variant={'h5'}>Sales</Typography>
                </Grid>
                <GridRow
                    Col1={() => <TextBox
                        type={'Sales'}
                        value={`<span style="color: ${dificlir.targetYTD > 100 ? 'green' : 'red'}">${dificlir.targetYTD.toFixed(1)} %</span>`}
                        title="Dificlir Target YTD"
                        lastUpdate={getLastUpdated('sales')}
                        moreInfo={{
                            measure: 'Dificlir (units)',
                            goalYTD: formatNumberSwedishStyle(dificlir.goalYTD),
                            currentYTD: formatNumberSwedishStyle(dificlir.currentYTD),
                            goalYear: formatNumberSwedishStyle(dificlir.target!),
                            currentYear: maxPeriod.getFullYear()
                        }}
                    />}
                    Col2={() => <TextBox
                        type={'Sales'}
                        title="Oral Asacol Target YTD"
                        value={`<span style="color: ${asacolOral.targetYTD > 100 ? 'green' : 'red'}">${asacolOral.targetYTD.toFixed(1)} %</span>`}
                        moreInfo={{
                            measure: 'Asacol Oral (SEK)',
                            goalYTD: formatCurrency(asacolOral.goalYTD),
                            currentYTD: formatCurrency(asacolOral.currentYTD),
                            goalYear: formatCurrency(asacolOral.target!)
                        }}
                        lastUpdate={getLastUpdated('sales')}
                    />}
                    Col3={() => <TextBox
                        type={'Sales'}
                        title="Asacol 1600 Target YTD"
                        value={`<span style="color: ${asacol1600.targetYTD > 100 ? 'green' : 'red'}">${asacol1600.targetYTD.toFixed(1)} %</span>`}
                        moreInfo={{
                            measure: 'Asacol 1600 (units)',
                            goalYTD: formatNumberSwedishStyle(asacol1600.goalYTD),
                            currentYTD: formatNumberSwedishStyle(asacol1600.currentYTD),
                            goalYear: formatNumberSwedishStyle(asacol1600.target!)
                        }}
                        lastUpdate={getLastUpdated('sales')}
                    />} />

                <GridRow
                    Col1={() => <CustomLineChart
                        goal={dificlir.target!}
                        goalMonth={dificlir.monthlyTarget}
                        data={dificlirSales?.sales} title={'Dificlir - units sold'} />}
                    Col2={() => <CustomLineChart
                        goal={asacolOral.target!}
                        goalMonth={asacolOral.monthlyTarget}
                        data={asacolOralSales?.sales} title={'Asacol Oral - sales (SEK)'}
                        type={'sales'}
                    />}
                    Col3={() => <CustomLineChart
                        goal={asacol1600.target!}
                        goalMonth={asacol1600.monthlyTarget}
                        data={asacolSales?.sales} title={'Asacol 1600 - units sold'} />} />

                <GridRow
                    Col2={() =>
                        <CustomGoogleLineChart
                            data={
                                [
                                    ['Month', 'ASACOL ORAL'],
                                    ...asacolOralSales?.share.map((item: IBrandSalesShare) => [item.month, item.share])!
                                ]}
                            title={'Asacol Oral - sales MS '}
                            vAxisTitle={"Share (%)"}
                            description={"The market share for Asacol ORAL for each month is calculated by dividing the accumulated sales of Asacol from the beginning of the year up to and including the current month by the accumulated sales of Oral 5-ASA products over the same time period. The formula is:" }
                            formula={"Asacol ORAL Market Share (Month X) = (Accumulated Sales of Asacol (Jan to Month X)) / (Accumulated Sales of Oral 5-ASA (Jan to Month X))"}
                        />
                    }
                    Col3={() =>
                        <CustomGoogleLineChart
                            data={
                                [
                                    ['Month', 'ASACOL 1600'],
                                    ...asacolSales?.share.map((item: IBrandSalesShare) => [item.month, item.share])!
                                ]}
                            title={'Asacol 1600 - sales MS '}
                            vAxisTitle={"Share (%)"}
                            description={"The market share for Asacol 1600 for each month is calculated by dividing the accumulated sales of Asacol from the beginning of the year up to and including the current month by the accumulated sales of Oral 5-ASA products over the same time period. The formula is:" }
                            formula={"Asacol 1600 Market Share (Month X) = (Accumulated Sales of Asacol (Jan to Month X)) / (Accumulated Sales of Oral 5-ASA (Jan to Month X))"}
                        />
                    }/>
                <GridRow
                    Col2={() =>
                        <CustomGoogleLineChart
                            data={
                                [
                                    ['Month', 'ASACOL Oral', 'Oral 5-ASA'],
                                    ...asacolOralSales?.growth.map((item) => [item.month, item.brandGrowth, item.segmentGrowth ])!
                                ]} title={'Asacol Oral - sales growth'}
                            vAxisTitle={"Growth (%)"}
                            description={"In the graph, the accumulated sales for both Asacol and Oral 5-ASA products for the current year are compared to those for the previous year. Each point on the graph represents a comparison of the accumulated sales from the beginning of the year up to the current month between the current and the previous year. The formula used to calculate the growth for a given month (e.g., March) is:" }
                            formula={"Growth March = ((Sales Jan-March Current Year) - (Sales Jan-March Previous Year)) / (Sales Jan-March Previous Year)"}
                        />
                    }
                    Col3={() =>
                        <CustomGoogleLineChart
                            data={
                                [
                                    ['Month', 'ASACOL 1600', 'Oral 5-ASA'],
                                    ...asacolSales?.growth.map((item) => [item.month, item.brandGrowth, item.segmentGrowth ])!
                                ]} title={'Asacol 1600 - sales growth'}
                            vAxisTitle={"Growth (%)"}
                            description={"In the graph, the accumulated sales for both Asacol and Oral 5-ASA products for the current year are compared to those for the previous year. Each point on the graph represents a comparison of the accumulated sales from the beginning of the year up to the current month between the current and the previous year. The formula used to calculate the growth for a given month (e.g., March) is:" }
                            formula={"Growth March = ((Sales Jan-March Current Year) - (Sales Jan-March Previous Year)) / (Sales Jan-March Previous Year)"}
                        />
                    }/>
            </React.Fragment>
        )
    }
    return (
        <Grid container>
            <Grid item xs={12} marginBottom={3} textAlign={'center'}>
                <Typography variant={'h4'}>Lag KPIs</Typography>
            </Grid>
            <Grid item xs={12}  >
                {!isDesktop && <Box sx={{backgroundColor: 'rgba(187, 215, 239, 1)'}}><LagKPIInner /></Box>}
                {isDesktop &&
                    <Paper sx={{backgroundColor: 'rgba(187, 215, 239, 1)', width: '100%', alignItems: 'center',m:0}}  >
                        <LagKPIInner />
                    </Paper>
                }
            </Grid>
        </Grid>
            )
}