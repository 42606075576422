import { useState, useEffect } from "react";
import {
    IAccountPlan,
    IAccountPlanRegion,
    IBrandRegion,
    IConsentRegion,
    IEventRegion,
    IRepEmail,
    IRepEmailRegion,
    ISales,
    ISalesSegment
} from "../../types.ts";

interface DashboardData {
    asacolSales: IBrandRegion | undefined;
    dificlirSales: IBrandRegion | undefined;
    asacolOralSales: IBrandRegion | undefined;
    medicalCoMeetings: any;
    repEmails: IRepEmailRegion | undefined
    consents: IConsentRegion | undefined
    events: IEventRegion | undefined
    asacolAccountPlans: IAccountPlanRegion | undefined;
    dificlirAccountPlans: IAccountPlanRegion | undefined;
    logs: any;
    lastLoginTime?: Date;
    setLastLoginTime?: (date: Date) => void;
    lastUpdated?: any;
    maxPeriod: Date;
}

const getSales = (data: any, brand: string, segment: string, region: string) => {
    return data.dashboardData.sales.find((item: ISales) => item.brand === brand)?.segments.find((item: ISalesSegment) => item.name === segment)?.regions.find((item: IBrandRegion) => item.name === region);
}
const getMaxPeriod = (data: any, brand: string, segment: string, region: string) => {
    return data.dashboardData.sales.find((item: ISales) => item.brand === brand).maxPeriod ?? new Date();
}

const useDashboardData = (country: string, data: any): DashboardData => {
    const [asacol1600Sales, setAsacol1600Sales] = useState<IBrandRegion | undefined>();
    const [asacolOralSales, setAsacolOralSales] = useState<IBrandRegion | undefined>();
    const [dificlirSales, setDificlirSales] = useState<IBrandRegion | undefined>();
    const [medicalCoMeetings, setMedicalCoMeetings] = useState();
    const [repEmails, setRepEmails] = useState<IRepEmailRegion>();
    const [consents, setConsents] = useState<IConsentRegion>();
    const [events, setEvents] = useState();
    const [asacolAccountPlans, setAsacolAccountPlans] = useState<IAccountPlanRegion>();
    const [dificlirAccountPlans, setDificlirAccountPlans] = useState<IAccountPlanRegion>();
    const [logs, setLogs] = useState<any>();
    const [lastUpdated, setLastUpdated] = useState<Date | undefined>();
    const [lastLoginTime, setLastLoginTime] = useState<Date | undefined>();
    const [maxPeriod, setMaxPeriod] = useState(new Date);
    const setDashBoardData = () => {
        if (data) {
            setAsacol1600Sales(getSales(data, 'Asacol', 'Asacol 1600', country));
            setDificlirSales(getSales(data, 'Dificlir', 'Dificlir', country));
            setAsacolOralSales(getSales(data, 'Asacol', 'Asacol Oral', country));
            setRepEmails(data.dashboardData.repEmails.regions.find((item: IRepEmailRegion) => item.name === country));
            setConsents(data.dashboardData.consents.regions.find((item: IConsentRegion) => item.name === country));
            setAsacolAccountPlans(data.dashboardData.accountPlans.find((item: IAccountPlan) => item.brand === 'Asacol').regions.find((item: IAccountPlanRegion) => item.name === country));
            setDificlirAccountPlans(data.dashboardData.accountPlans.find((item: IAccountPlan) => item.brand === 'Dificlir').regions.find((item: IAccountPlanRegion) => item.name === country));
            setEvents(data.dashboardData.events.regions.find((item: IEventRegion) => item.name === country));
            if (data.lastLoginTime) setLastLoginTime(data.lastLoginTime);
            setLastUpdated(data.dashboardData.lastUpdated);
            setLogs(data.dashboardData.logs.filter((item: any) => item.message));
            setMaxPeriod(new Date(getMaxPeriod(data, 'Asacol', 'Asacol 1600', country)));
        }

    };
    useEffect(() => setDashBoardData(), []);
    useEffect(() => setDashBoardData(), [data, country]);


    return { asacolSales: asacol1600Sales, dificlirSales, medicalCoMeetings, repEmails, consents, logs, lastLoginTime, setLastLoginTime, lastUpdated, maxPeriod, asacolOralSales, events, asacolAccountPlans, dificlirAccountPlans };
};

export default useDashboardData;
