import React, {useEffect} from "react";
import "./App.css";
import Dashboard from "./components/Dashboard";
import { AppContext } from "./AppContext";
import {SendMessage} from "react-use-websocket";
import {MessageDisplay} from "./components/MessageDisplay";
import useDashboardData from "./components/hooks/useDashboardData";
import useWebSocketConnection from "./components/hooks/useWebSocketConnection";
import {getIdToken} from "./authentication/azureAuthProvider";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {Status} from "./types/Status";
export const App = ({lastMessage, sendMessage, getWebSocket,setShouldConnect, ppID}: {lastMessage: MessageEvent | null, sendMessage: SendMessage, getWebSocket: any, setShouldConnect: any, ppID?: string}) => {
    const theme = useTheme();
    const [country, setCountry] = React.useState(localStorage.getItem('country')??"Nordic");
    const [isAuthorized, setIsAuthorized] = React.useState(false);
    const { payload, authenticating } = useWebSocketConnection(lastMessage, sendMessage, getWebSocket, setShouldConnect, setIsAuthorized, ppID);
    const dashboardData = useDashboardData(country, payload);
    const [authStatus, setAuthStatus] = React.useState(102);
    const [authError, setAuthError] = React.useState('');
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    useEffect(() => {
        (async () => {
            if (ppID) return;
            if (await getIdToken()) setIsAuthorized(true)
        })()

        window.addEventListener('auth.error', (e: any) => {
            setAuthStatus(e.detail.status);
            setAuthError(e.detail.error);
            setIsAuthorized(false);
        })
    }, [])
    return (
        <React.Fragment>
            {
               !authenticating && isAuthorized && dashboardData.asacolSales &&
                <AppContext.Provider value={{country, setCountry,
                    asacolSales: dashboardData.asacolSales,
                    dificlirSales: dashboardData.dificlirSales,
                    asacolOralSales: dashboardData.asacolOralSales,
                    medicalCoMeetings: dashboardData.medicalCoMeetings,
                    repEmails: dashboardData.repEmails,
                    consents: dashboardData.consents,
                    events: dashboardData.events,
                    asacolAccountPlans: dashboardData.asacolAccountPlans,
                    dificlirAccountPlans: dashboardData.dificlirAccountPlans,
                    lastLoginTime: dashboardData.lastLoginTime,
                    setLastLoginTime: dashboardData.setLastLoginTime,
                    lastUpdated: dashboardData.lastUpdated,
                    logs: dashboardData.logs,
                    maxPeriod: dashboardData.maxPeriod,
                    ppID,
                    isDesktop
                }}>
                    <Dashboard/>
                </AppContext.Provider>
            }
            {authenticating && <MessageDisplay type={Status.PROCESSING}/>}
            {!isAuthorized && <MessageDisplay type={authStatus} reason={authError} />}
        </React.Fragment>

    );
};