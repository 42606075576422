import Grid from "@mui/material/Grid";
import {Typography} from "@mui/material";
import {GridRow} from "./GridRow";
import {TextBox} from "./TextBox";

import * as React from "react";
import {CustomBarChart} from "../Charts/CustomBarChart";
import {useContext} from "react";
import {AppContext} from "../../AppContext";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

export function formatNumberSwedishStyle(value: number) {
    return new Intl.NumberFormat('sv-SE', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }).format(value);
}
export const QuantityKPI = () => {
    const {repEmails, consents,  country, lastUpdated, maxPeriod, isDesktop} = useContext(AppContext);
    const maxMonth = maxPeriod.getMonth()+1;
    const getLastUpdated = (type: string) => {
        const tmpLastUpdated = lastUpdated[country][type];
        if (tmpLastUpdated) return new Date(tmpLastUpdated);
        return new Date();
    }
    const repEmailsTarget = repEmails?.target?.sentEmails??0;
    const consentsTarget = consents?.target?.newConsents??0;
    const consentsYTD = consents?.consentsPerMonth.reduce((acc: number, curr: any) => acc + curr.value, 0);
    const QuantityKPIInner = () => {
        return (
            <React.Fragment>
                <Grid item xs={12} marginBottom={3} textAlign={'left'}>
                    <Typography paddingBottom={0} paddingTop={1} paddingLeft={2} variant={'h5'}>Quantity</Typography>
                </Grid>
                <GridRow
                    Col1={() => <TextBox
                        type={'Quantity'}
                        title="Share of voice"
                        value={`<span style="font-style: italic; font-size: small" >No data yet...</span>`}
                        lastUpdate={getLastUpdated('sales')}
                    />}
                    Col2={() => <TextBox
                        type={'Quantity'}
                        title="Total consents (new consents)"
                        value={`${formatNumberSwedishStyle(consents?.totalConsents!)} (<span style="color: ${(consentsYTD??0) >= ((consentsTarget / 12) * maxMonth) ? 'green' : 'black'}">+${consentsYTD}</span>)`}
                        lastUpdate={getLastUpdated('consents')}
                        moreInfo={{
                            measure: 'New consents (including consent depot)',
                            goalYTD: (consentsTarget / 12) * maxMonth,
                            currentYTD: `${consentsYTD}`,
                            goalYear: `${consentsTarget}`
                        }}
                    />}
                    Col3={() => <TextBox
                        type={'Quantity'}
                        title="RepEmail(s) sent"
                        value={`<span >${formatNumberSwedishStyle(repEmails?.totalSentRepEmails!)}</span>`}
                        lastUpdate={getLastUpdated('repEmails')}
                        moreInfo={{
                            measure: 'RepEmail(s) sent',
                            goalYTD: (repEmailsTarget / 12) * maxMonth,
                            currentYTD: `${repEmails?.totalSentRepEmails}`,
                            goalYear: `${repEmailsTarget}`
                        }}
                    />} />

                <GridRow
                />
                <GridRow
                    Col2={() => <CustomBarChart
                        yAxisTitle={'Consents'}
                        data={consents?.consentsPerMonth} title={'New consents'} />}
                    Col3={() =>
                        <CustomBarChart
                            yAxisTitle={'Sent Rep Emails'}
                            data={repEmails?.sentRepEmailsPerMonth} title={'Rep Emails'} />
                    }/>
            </React.Fragment>
        )
    }
    return (
        <React.Fragment>
            {isDesktop &&  <Paper sx={{  backgroundColor: '#eff6fb', mt: 5, ml: 0, mr: 0}}><QuantityKPIInner /></Paper>}
            {!isDesktop &&  <Box sx={{  backgroundColor: '#eff6fb', mt: 5}}><QuantityKPIInner /></Box>}
        </React.Fragment>
    )
}