import {useContext, useEffect} from "react";
import {AppContext} from "../../AppContext";
import Grid from "@mui/material/Grid";
import {Typography} from "@mui/material";
import {GridRow} from "./GridRow";
import {TextBox} from "./TextBox";
import {CustomBarChart} from "../Charts/CustomBarChart";
import * as React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {formatNumberSwedishStyle} from "./QuantityKPI";

export const AccountPlanningKPI = () => {
    const {country, lastUpdated, isDesktop, asacolAccountPlans, dificlirAccountPlans} = useContext(AppContext);
    const getLastUpdated = (type: string) => {
        const tmpLastUpdated = lastUpdated[country][type];
        if (tmpLastUpdated) return new Date(tmpLastUpdated);
        return new Date();
    }
    const asacolAccountPlansTarget = asacolAccountPlans?.target?.approvedAccountPlans??0;
    const dificlirAccountPlansTarget = dificlirAccountPlans?.target?.approvedAccountPlans??0;
    const AccountPlanningKPIInner = () => {
        return (
            <React.Fragment>
                <Grid item xs={12} marginBottom={3} textAlign={'left'}>
                    <Typography paddingBottom={0} paddingTop={1} paddingLeft={2} variant={'h5'}>Account planning (completed)</Typography>
                </Grid>
                <GridRow
                    Col1={() => <TextBox
                        type={'Account planning'}
                        title="Dificlir"
                        value={`${formatNumberSwedishStyle(dificlirAccountPlans?.totalAccountPlans??0)}`}
                        lastUpdate={getLastUpdated('accountPlans')}
                        moreInfo={{
                            measure: 'Completed Asacol Account Plans',
                            currentYTD: `${dificlirAccountPlans?.totalAccountPlans??0}`,
                            goalYear: `${asacolAccountPlansTarget}`
                        }}
                    />}
                    Col3={() => <TextBox
                        type={'Account planning'}
                        title="Asacol"
                        value={`${formatNumberSwedishStyle(asacolAccountPlans?.totalAccountPlans??0)}`}
                        lastUpdate={getLastUpdated('accountPlans')}
                        moreInfo={{
                            measure: 'Completed Dificlir Account Plans',
                            currentYTD: `${asacolAccountPlans?.totalAccountPlans??0}`,
                            goalYear: `${dificlirAccountPlansTarget}`
                        }}
                    />}/>
                <GridRow
                />
            </React.Fragment>
        )
    }
    return (
        <React.Fragment>
            {isDesktop &&  <Paper sx={{  backgroundColor: '#eff6fb', mt: 5, ml: 0, mr: 0}}><AccountPlanningKPIInner /></Paper>}
            {!isDesktop &&  <Box sx={{  backgroundColor: '#eff6fb', mt: 5}}><AccountPlanningKPIInner /></Box>}
        </React.Fragment>
    )
}