import {createContext} from 'react'
import {IBrandRegion, IRepEmail, IRepEmailRegion, IConsentRegion, IEventRegion, IAccountPlanRegion} from "./types.ts";

interface AppContextType {
    country: string;
    setCountry: (country: 'Norway' | 'Sweden' | 'Denmark' | 'Finland' | 'Nordic') => void;
    asacolSales?: IBrandRegion | undefined;
    dificlirSales?:  IBrandRegion | undefined;
    asacolOralSales?:  IBrandRegion | undefined;
    events?: IEventRegion | undefined;
    medicalCoMeetings?: any;
    repEmails?: IRepEmailRegion | undefined
    consents?: IConsentRegion | undefined
    asacolAccountPlans?: IAccountPlanRegion | undefined;
    dificlirAccountPlans?: IAccountPlanRegion | undefined;
    gastroConsents?: any;
    infectionConsents?: any;
    logs?: any;
    lastLoginTime?: Date;
    setLastLoginTime?: (date: Date) => void;
    lastUpdated?: any;
    maxPeriod: Date;
    ppID?: string;
    isDesktop?: boolean;
}
export const AppContext = createContext<AppContextType>({
    country: 'Nordic',
    maxPeriod: new Date(),
    setCountry: (country: 'Norway' | 'Sweden' | 'Denmark' | 'Finland' | 'Nordic') => {}
});
